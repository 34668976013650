<template>
  <div class="measurement-block-onscroll">
    <div class="mb-os-content">
      <a href="javascript:void(0);" class="measurement-btn">
        <span v-if="isLoggedIn">Measurements</span>
        <span v-else>Let's find your perfect fit!</span>
        <svg class="arrow-icon" width="24" height="25" viewBox="0 0 24 25" fill="none"
          xmlns="http://www.w3.org/2000/svg">
          <path d="M6 9.22363L12 15.2236L18 9.22363" stroke="#F2C840" stroke-width="2" stroke-linecap="round"
            stroke-linejoin="round" />
        </svg>
      </a>
      <div class="mb-os-form">
        <div class="home-banner-form" :class="{'measurement-block-email': showEmailInput}">          
          <div class="text-box">
            <label :class="{ 'active': waist }" for="waistMeasure">Waist<sup>*</sup></label>
            <input id="waistMeasure" type="text" v-model="waist" />
            <span v-for="error in v$.waist.$errors" :key="error.uid" class="label-error">{{ error.$message }}</span>
          </div>
          <div class="text-box">
            <label :class="{ 'active': hip }" for="hipsMeasure">Hips<sup>*</sup></label>
            <input id="hipsMeasure" type="text" v-model="hip" />
            <span v-for="error in v$.hip.$errors" :key="error.uid" class="label-error">{{ error.$message }}</span>
          </div>
          <div class="text-box">
            <label :class="{ 'active': thigh }" for="thighMeasure">Thigh<sup>*</sup></label>
            <input id="thighMeasure" type="text" v-model="thigh" />
            <span v-for="error in v$.thigh.$errors" :key="error.uid" class="label-error">{{ error.$message }}</span>
          </div>
          <div class="text-box">
            <label :class="{ 'active': inseam }" for="inseamMeasure">Inseam<sup>*</sup></label>
            <input id="inseamMeasure" type="text" v-model="inseam" />
            <span v-for="error in v$.inseam.$errors" :key="error.uid" class="label-error">{{ error.$message }}</span>
          </div>
          <div class="text-box text-box-email" v-if="showEmailInput">
            <label :class="{ 'active': email }" for="emailBanner">Email<sup>*</sup></label>
            <input id="emailBanner" type="text" autocomplete="off" v-model="email" />
            <span v-for="error in v$.email.$errors" :key="error.uid" class="label-error">{{ error.$message }}</span>
          </div>
          <div class="home-form-cta">
            <button type="submit" class="fg-btn-primary w-100" @click="submitMeasurementForm">
              <span v-if="measurementThere">Update</span>
              <span v-else>Calculate</span>
              <svg width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M10 0.333496C10 1.39065 10.9167 2.97259 11.8434 4.30114C13.0367 6.01164 14.4608 7.50591 16.095 8.64678C17.3192 9.50107 18.8058 10.3211 20 10.3211M10 20.3335C10 19.2763 10.9167 17.6944 11.8434 16.3658C13.0367 14.6554 14.4608 13.1611 16.095 12.0202C17.3192 11.1659 18.8058 10.3458 20 10.3458M20 10.3335H0"
                  stroke="#0F2C66" stroke-width="2" />
              </svg>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  // import $ from "jquery";
  import axios from "axios";
  import { gsap, ScrollTrigger } from "gsap/all";

  import { useVuelidate } from '@vuelidate/core';
import { required, helpers, between, email } from '@vuelidate/validators';

  export default {
    data () {
      return {
        v$: useVuelidate(),
        waist: "",
        hip: "",
        thigh: "",
        inseam: "",
        email: "",
        showEmailInput: true,
	measurementThere: false
      }
    },
    validations () {
      return {
        waist: {
          required: helpers.withMessage('Waist is required.', required),
          between: helpers.withMessage('Invalid value.', between(11, 99))
        },
        hip: {
          required: helpers.withMessage('Hips is required.', required),
          between: helpers.withMessage('Invalid value.', between(11, 99))
        },
        thigh: {
          required: helpers.withMessage('Thigh is required.', required),
          between: helpers.withMessage('Invalid value.', between(11, 99))
        },
        inseam: {
          required: helpers.withMessage('Inseam is required.', required),
          between: helpers.withMessage('Invalid value.', between(11, 99))
        },
        email: {
          required: helpers.withMessage('Email is required.', required),
          email: helpers.withMessage('Invalid email address.', email),
        },
      }
    },
    computed: {
      loggedInEmail: function () {
        return this.$store.getters.getEmail;
      },
      isLoggedIn: function() {
        return this.$store.getters.isAuth;
      },
      measurementType: function () {
        return localStorage.getItem("measurementType");
      }
    },
    methods: {
      submitMeasurementForm: async function () {
        this.v$.$touch();
        const validateForm = await this.v$.$validate();
        if (!validateForm) return;
        // this.$store.getters['pages/measurementFromUser'];
        this.$store.dispatch('products/measurementFromUser', {
          waist: this.waist,
          hip: this.hip,
          thigh: this.thigh,
          inseam: this.inseam,
          email: this.email,
          measurementType: this.measurementType ? this.measurementType : "inches"
        });
        this.$router.push('/products');
        this.$router
          .push({path: "/products"})
          .then(() => { this.$router.go(0) }); // router.go(0) for reload product page

        if (this.isLoggedIn == false) {
          // call api for measurement
          await axios.post(`${process.env.VUE_APP_API_BASE_URL}/measurement/save`, {
            waist: this.waist,
            hip: this.hip,
            thigh: this.thigh,
            inseam: this.inseam,
            email: this.email
          });
        }
        return;
      }
    },
    mounted() {
      gsap.registerPlugin(ScrollTrigger);

      let measurement = this.$store.getters['products/getMeasurementFromLocal'];
      this.waist = measurement.waist;
      this.hip = measurement.hip;
      this.thigh = measurement.thigh;
      this.inseam = measurement.inseam;
      this.email = measurement.email;

      if (this.waist && this.hip && this.thigh && this.inseam) {
        this.measurementThere = true;
      } else {
        this.measurementThere = false;
      }

      if (this.isLoggedIn) {
        this.showEmailInput = false;
      }

      /*Measurements button to hide on scroll*/
      const showMeasurementAnim = gsap
        .from(".measurement-block-onscroll", {
          /* opacity: 0, */
          display: 'none',
          paused: true,
          duration: 0.1,
        })
        .progress(1);

      ScrollTrigger.create({
        start: "top top",
        end: 999999,
        onUpdate: (self) => {
          self.direction === -1 ? showMeasurementAnim.play() : showMeasurementAnim.reverse();
        },
      });
      /*Measurements button to hide on scroll*/
    }
  }
</script>

<style></style>
